<template>
  <div class="container">
    <div class="row mb-3 margin-top-0421">
      <div class="col-sm-12">
        <img
          src="/imgc/blog/8/car_travel.webp"
          class="img-fluid rounded w-100 img-thumbnail"
          alt="An Air and Land Travel Boom is on the way"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <h1 class="text-primary">An Air and Land Travel Boom is on the way!</h1>
        <small>
          <i class="fa fa-clock text-secondary"></i> 26 Aug, 2021 |
          <i class="fa fa-user text-secondary"></i> Admin
        </small>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-sm-12 text-justify">
        Statistically speaking, air travelers are growing at an ever-increasing rate! As a result of
        land travel, the number of people is also increasing at an accelerated rate and will
        continue to do so for many years... The majority of leisure travelers in the United States
        travel by airplane at least once a year before the pandemic. <br />
        <img
          src="/imgc/blog/8/air_travel.jpg"
          class="img-fluid rounded w-100 img-thumbnail"
          alt="An Air and Land Travel Boom is on the way - air travel"
        />
        Given that we were nearly entirely affected by the pandemic in the past few months following
        it, it is impressive that nearly as many have cleared the national health system of the
        disease. <br />
        The survey also found that 19% of respondents have not flown since March 2020, but have
        booked flights for later in the year. The number of passengers screened at TSA airports may
        surpass the 2020 level by the summer of 2021, and could also approach two million by the end
        of summer 2021. Customers combining air and ground travel might discover a shocking surprise
        at the car rental counter when they combine the two modes of transportation.<br />
        <img
          src="/imgc/blog/8/car_travel2.jpg"
          class="img-fluid rounded w-100 img-thumbnail"
          alt="An Air and Land Travel Boom is on the way - air travel"
        />
        The rental car companies were desperate for cash early in the pandemic, so they sold off
        their fleets. Many markets have seen an increase in car rental prices as a result of the
        increase in demand. It is possible that some customers will not be able to get cars despite
        reservations. <br />
        With a return of travel demand, not only are car rental prices rising. There are also higher
        airfares and hotel rates, with airfares up an average of 9% from last year. Hotel rates are
        also increasing, though not yet returning to pre-pandemic levels.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Blog8',
  created() {
    document.title = 'Trepr - An Air and Land Travel Boom is on the way!';
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        'content',
        "Travel apps are your best friend when travelling to a strange place alone. Tourists like to fly abroad on their own but it's always nice to have some kind of knowledge about where you're going and what you'll find there when you get there. "
      );
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        'content',
        'Trepr,How to be a Good Traveller,Your Best travel apps for the trip, France; Flights have started, Package services, Flight companionship, Flight companion for elders, Package shipment, Shopping conceirge'
      );
  },
};
</script>
